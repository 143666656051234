<template>
  <div>
    <member-menu/>
    <b-row>
      <b-container fluid class="filter">
        <div class="iq-card p-1">
          <div class="row">
            <div class="col-md-3 my-1">
              <input type="text" placeholder="search by member name" class="form-control" v-model="filter.name" @input="filterTable" >
            </div>
            <div class="col-md-3 my-1">
              <select class="form-control" v-model="filter.type" @change="filterTable">
                <option selected disabled value="">select Type...</option>
                <option value="guest">guest</option>
                <option value="member">member</option>
              </select>
            </div>
            <div class="col-md-1 d-flex justify-content-center align-items-center" v-if="filter.name || filter.type">
              <span class="text-danger cursor-pointer" @click="resetFilter()">Clear All</span>
            </div>
          </div>
        </div>
      </b-container>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">list Of Members</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else sort-icon-left primary-key="name" striped responsive :fields="columns" :items="getAllMembers" class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(image)="data1">
               <img v-if="data1.item.image" :src="data1.item.profile" class="avatar-60 rounded-circle"/>
                <img v-else src="../../assets/images/user/user-11.png" class="avatar-60 rounded-circle"/>
              </template>
              <template v-slot:cell(phone)="data1">
                <p class="holdSelection m-0" v-if="data1.item.id==showPhone">{{data1.item.phone}}</p>
                <b-button v-else variant=" iq-bg-success mr-0 mb-0" size="sm" :id="data1.item.id" @click="showPhone = data1.item.id" ><i class="las la-mobile"></i>Show Phone</b-button>
              </template>
              <template v-slot:cell(action)="data1">
                <router-link :to="{name:'profile', params:{id :data1.item.id}}"><b-button variant=" iq-bg-primary mr-0 mb-0" size="sm"><i class="las la-eye"></i></b-button></router-link>
              </template>
            </b-table>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="changeContent"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
const addProjectModal = () => import('../../components/projects/projectModal')
export default {
  name: 'allMembers',
  data () {
    return {
      showPhone: '',
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      filter: {
        name: '',
        type: ''
      },
      currentPage: 1,
      per_page: 10,
      total: 6,
      loadTable: true,
      columns: [
        { label: 'Image', key: 'image', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Phone', key: 'phone', class: 'text-left', sortable: true },
        { label: 'Membership_id', key: 'membership_id', class: 'text-left', sortable: true },
        { label: 'Email', key: 'email', class: 'text-left', sortable: true },
        { label: 'Type', key: 'type', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-left' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getAllMembers'])
  },
  methods: {
    resetFilter () {
      this.filter = {
        name: '',
        type: ''
      }
      this.filterTable()
    },
    filterTable () {
      this.loadTable = true
      this.$store.dispatch('getAllProject', { name: this.filter.name, type: this.filter.name }).then(data => {
        this.currentPage = data.data.meta.currentPage
        this.per_page = data.data.meta.per_page
        this.total = data.data.meta.total
        this.loadTable = false
      })
    },
    changeContent () {
      this.loadTable = true
      this.$store.dispatch('getAllMembers', { pageNumber: this.currentPage }).then(() => {
        this.loadTable = false
      })
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    addProjectModal
  },
  mounted () {
    core.index()
  },
  created () {
    this.loadTable = true
    this.$store.dispatch('getAllMembers', { pageNumber: this.currentPage }).then(data => {
      this.currentPage = data.data.meta.currentPage
      this.per_page = data.data.meta.per_page
      this.total = data.data.meta.total
      this.loadTable = false
    })
  }
}
</script>
<style>
.colorSpan{
  width: 20px;
  height: 20px;
}
th{
  white-space: pre !important;
  vertical-align: middle !important;
}
td{
  white-space: pre !important;
  vertical-align: middle !important;
}
</style>
